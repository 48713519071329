<div class="container text-center">
  <h1>Problemer med at komme ind på appen via Single Sign-on?</h1>
  <h3>
    Opret en rapport og del den med TeamEffect eller din IT afdeling for at få
    hjælp.
  </h3>

  <div style="margin-top: 50px;">
    <a href="/saml/reports/login" class="btn btn-primary btn-lg">
      <div class="glyphicon glyphicon-log-in" />
      Opret rapport
    </a>
  </div>
</div>
